*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container{
    display:flex;
    flex-direction: row;
}

.container img{
    width:150px;
}

.first h1{
    font-size: 20px;
}

@media(max-width:768px){

    .container{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .first h1{
        font-size: 10px;
    }
    
    .first p{
        font-size: 10px;
       
    }
}

@media(max-width:560px){

    .container img{
        width:120px;
    }
    
}

@media(max-width:380px){
    .container img{
        width:100px;
    }
}