*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container img{
    width:400px;
    
    
    border-radius: 200px;
    margin-top: 70px;
    margin-left:70px;

}

.container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    
}


.smallcontainer{
    display:flex;
    flex-direction: column;
    padding:16px;
    gap:16px;
    margin-right:70px;
}

.first h1{
    text-align: start;
    font-size: 38px;
    font-weight: bold;

}

.second h2{
    text-align: start;
    font-size: 30px;
}

.second p{
    text-align:start;
    font-size: 20px;
}

.third p{
    text-align: start;
    font-size: 20px;
}

.fourth p{
    text-align:start;
    font-size: 20px;
}

.fifth{
    display:flex;
    flex-direction: row;
    gap:16px;
}


.one{
    width:100px;
    height:100px;
    background-color: rgb(189, 245, 24);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;

}

.two{
    width:100px;
    height:100px;
    background-color: rgb(244, 9, 60);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;

}
.three{
    width:100px;
    height:100px;
    background-color: rgb(243, 111, 10);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;
}

.four{
    width:100px;
    height:100px;
    background-color: rgb(22, 200, 232);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;
}

.five{
    width:100px;
    height:100px;
    background-color: rgb(21, 204, 79);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;
}


@media(max-width:1000px){

    .container img{
        width:300px;
        border-radius: 200px;
        margin-top: 70px;
        margin-left:10px;
    
    }

    .container{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items:center;
        
    }

    
.one{
    width:80px;
    height:80px;
    background-color: rgb(189, 245, 24);
    display:flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    border-radius: 50px;
    padding:8px;

}

.two{
    width:80px;
    height:80px;
    background-color: rgb(244, 9, 60);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:8px;
    font-size: 14px;

}
.three{
    width:80px;
    height:80px;
    background-color: rgb(243, 111, 10);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:8px;
    font-size: 14px;
}

.four{
    width:80px;
    height:80px;
    background-color: rgb(22, 200, 232);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;
}

.five{
    width:80px;
    height:80px;
    background-color: rgb(21, 204, 79);
    display:flex;
    text-align: center;
    align-items: center;
    border-radius: 50px;
    padding:16px;
}

}

@media(max-width:800px){

    .container{
        display:flex;
        flex-direction: column-reverse;
        justify-content:center;
        align-items:center;
        
    }

   .container img{
    margin-top: 30px;
    width:350px;
   }

   .smallcontainer{
    display:flex;
    flex-direction: column;
    padding:16px;
    gap:16px;
    margin-right:10px;
    margin-left: 10px;


}
   
}

@media(max-width:600px){



.second h2{
    text-align: start;
    font-size: 20px;
}

.second p{
    text-align:start;
    font-size: 15px;
}

.third p{
    text-align: start;
    font-size: 15px;
}

.fourth p{
    text-align:start;
    font-size: 15px;
}

.one{
    width:70px;
    height:70px;
    font-size: 12px;
    padding:10px;
    

}

.two{
    width:70px;
    height:70px;
    
    padding:10px;
    font-size: 12px;

}
.three{
    width:70px;
    height:70px;
    
    padding:10px;
    font-size: 12px;
}

.four{
    width:70px;
    height:70px;
    padding:10px;
    font-size:14px;
    
}

.five{
    width:70px;
    height:70px;
    font-size: 14px;
   
}
}

@media(max-width:450px){

    .container img{
        width:300px;

    }

    .fifth{
        display:flex;
        flex-direction: row;
        gap:6px;
    }

    .one{
        width:60px;
        height:60px;
        font-size: 10px;
        padding:8px;
        
    
    }
    
    .two{
        width:60px;
        height:60px;
        
        padding:9px;
        font-size: 10px;
    
    }
    .three{
        width:60px;
        height:60px;
        
        padding:8px;
        font-size: 10px;
    }
    
    .four{
        width:60px;
        height:60px;
        padding:8px;
        font-size:14px;
        
    }
    
    .five{
        width:60px;
        height:60px;
        font-size: 14px;
        padding:10px;
       
    }
}