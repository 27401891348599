*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.bdacontainer h1{
    text-align: center;
    font-size: 32px;
    font-weight: bold;
}

.container{
    
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top:20px;
    margin-bottom: 20px;
}

.image img{
    width:300px;
}

.image{
    display:flex;
}
.image p{
    margin-top:10px;
}

.first{
    width:500px;
   
   background-color: rgb(250, 253, 255);
    display:flex;
    flex-direction: column;
    gap:12px;
    padding:16px;
}
.second{
    padding:16px;
    width:500px;
    text-align: center;
    background-color: rgb(99, 179, 250);
}

.second button{
    background-color: blue;
    padding:8px;
    border-radius: 8px;
    margin-top: 5px;
    color:white;
    font-weight: bold;
}

.second button:hover{
    cursor:pointer;
    background-color: rgb(245, 8, 178);
    color:white;
    font-weight: bold;
}

.one{
    width:400px;
    height:15px;
    background-color: antiquewhite;
    border-radius: 4px;
}

.two{
    width:400px;
    height:15px;
    background-color: antiquewhite;
    border-radius: 4px;
}

.three{
    width:400px;
    height:15px;
    background-color: antiquewhite;
    border-radius: 4px;
}

.four{
    width:400px;
    height:15px;
    background-color: antiquewhite;
    border-radius: 4px;
}

.five{
    width:400px;
    height:15px;
    background-color: antiquewhite;
    border-radius: 4px;
}

.o{
    width:390px;
    height:15px;
    background-color: rgb(245, 179, 24);
}

.t{
    width:385px;
    height:15px;
    background-color: rgb(245, 179, 24);
}

.th{
    width:380px;
    height:15px;
    background-color: rgb(245, 179, 24);
}

.f{
    width:365px;
    height:15px;
    background-color: rgb(245, 179, 24);
}

.fi{
    width:350px;
    height:15px;
    background-color:rgb(245, 179, 24);
}


@media(max-width:550px){

    .first{
        width:400px;
        margin-bottom: 10px;
        
        
    }
    .second{
        padding:16px;
        width:400px;
        
    }
    

    .one{
        width:350px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .two{
        width:350px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .three{
        width:350px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .four{
        width:350px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .five{
        width:350px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }

    .image img{
        width:250px;
    }

    .o{
        width:330px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }
    .t{
        width:300px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }
    .th{
        width:280px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }
    .f{
        width:270px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }
    .fi{
        width:230px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }
    
}

@media(max-width:410px){

    .first{
        width:330px;
       
        padding:8px;
        margin-bottom: 10px;
       
    }
    .second{
        padding:16px;
        width:330px;
       
    }

    .one{
        width:310px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .two{
        width:310px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .three{
        width:310px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .four{
        width:310px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }
    
    .five{
        width:310px;
        height:15px;
        background-color: antiquewhite;
        border-radius: 4px;
    }

    .image img{
        width:200px;
        height:60px;
    }
    
    .image p{
        font-size: 15px;
    }

    .o{
        width:290px;
        height:15px;
        background-color: rgb(245, 179, 24);
    }


}
