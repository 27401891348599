*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container{
    padding:16px;
    background-color: rgb(252, 212, 159);
    text-align: center;
}
.container p{
    font-weight: 500;
}

.container h1{
    font-size: 32px;
    font-weight: bold;
}