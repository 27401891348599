*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.container{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2px;
    padding-top:20px;
    padding-bottom:20px;
    margin-bottom:10px;
    margin-top:20px;
    border-width: 2px;
    

}
.third{
    padding:4px;
}

@media(max-width:700px){

    .container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap:16px;
        
    
    }


}