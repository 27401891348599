*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

marquee{
    margin-top: 10px;
}

.policy{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top:10px;
}

.policy a{
    background-color: blue;
    padding:8px;
    border-radius: 4px;
    color:white;
    font-weight: bold;
}

.policy a:hover{

    cursor:pointer;
    background-color: rgb(245, 8, 178);
    color:white;
    font-weight: bold;
}